function Get(url){
  const xhr = new XMLHttpRequest();
  xhr.open("GET",url,false);
  xhr.send(null);
  return xhr.responseText;
}

const urlParams = new URLSearchParams(window.location.search);
let paramPostcode = urlParams.get("postcode");
let contacts;
const paramConstituency = urlParams.get("constituency");
const paramDailConstituency = urlParams.get("dialconstituency");
const paramCountry = urlParams.get("country");
const paramName = urlParams.get("name");
const allRepresentatives = [];
const constRepContentTemplate = '<p class="mb-4 text-base">{representative_name} ({party})<br>{constituency}<br><strong><a href="mailto:{email}?Subject={subject}" class="no-underline font-bold">{email}</a></p>';
const repContentTemplate = '<p class="mb-4 text-base">{representative_name} ({party})<br><a href="mailto:{email}?Subject={subject}" class="no-underline font-bold">{email}</a></p>';
const copiedMessageTemplate = '<p>Your email has been copied. Now go to your email client, paste the message into a new email and send it to <a href="mailto:{email}?Subject={subject}">{email}</a></p>';
if (paramPostcode !== null) {
  paramPostcode = paramPostcode.toUpperCase();
}

if (paramCountry !== null) {
  contacts = Get(`/json/contacts.json?country=${paramCountry}`);
}

let emailContent;

function getMP(postcode) {
  const url = `https://www.theyworkforyou.com/api/getMP?key=FMpFXGHD2F8mGbLNf8EoWHUq&postcode=${postcode.replace(/ /g,"+")}&output=json`;
  return Get(url);
}

function getMSP(postcode) {
  const url = `https://www.theyworkforyou.com/api/getMSP?key=FMpFXGHD2F8mGbLNf8EoWHUq&postcode=${postcode.replace(/ /g,"+")}&output=json`;
  return Get(url);
}

function getMLA(postcode) {
  const url = `https://www.theyworkforyou.com/api/getMLA?key=FMpFXGHD2F8mGbLNf8EoWHUq&postcode=${postcode.replace(/ /g,"+")}&output=json`;
  return Get(url);
}

function getCountry(postcode) {
  const scotlandPostcodes = ["AB", "DD", "DG", "EH", "FK", "G", "HS", "IV", "KA", "KW", "KY", "ML", "PA", "PH", "TD", "ZE"];
  const walesPostcodes = ["CF", "CH", "HR", "LD", "LL", "NP", "SA", "SY"];
  const niPostcodes = ["BT"];
  const regionCode = postcode.substring(0, 2).toUpperCase();
  const singleRegionCode = postcode.substring(0, 1).toUpperCase();
  let country = "england"

  if (scotlandPostcodes.includes(regionCode) || scotlandPostcodes.includes(singleRegionCode)) {
    country = "scotland";
  } else if (walesPostcodes.includes(regionCode)) {
    country = "wales";
  } else if (niPostcodes.includes(regionCode)) {
    country = "northern-ireland";
  }

  return country;

}

function getObjectByProperty(object, property, value) {
  return object.find((x) => x[property] === value);
}

function getEmail(name) {
  const contactDetails = getObjectByProperty(JSON.parse(contacts), "full_name", name);
  if (contactDetails !== undefined) {
    return contactDetails.email;
  }
  return "";
}

function getRepContent(template, rep, isIreland, subject) {
  let email;
  if (isIreland) {
    email = rep.email;
  } else {
    email = getEmail(rep.full_name);
  }

  return template
    .replace(/{representative_name}/g, rep.full_name)
    .replace(/{party}/g, rep.party)
    .replace(/{constituency}/g, rep.constituency)
    .replace(/{email}/g, email)
    .replace(/{subject}/g, subject);
}

function listRegionalReps(split, reps, type, el) {
  const header = document.createElement("h2");
  const content = document.createElement("div");
  const regionalHeader = document.createElement("h2");
  const regionalContent = document.createElement("div");
  const subjectEl = document.querySelector("[data-email-subject]");
  const regionalConstituencies = [];
  let uniqueConstituencyName;
  let commonConstituencyName;

  reps.forEach((rep) => {
    regionalConstituencies.push(rep.constituency);
    allRepresentatives.push({"full_name": rep.full_name, "email": getEmail(rep.full_name)});
  });

  if (split) {
    uniqueConstituencyName = regionalConstituencies[
      regionalConstituencies.findIndex(
        word => regionalConstituencies.filter(x => x === word).length === 1
      )
    ];
  }

  reps.forEach((rep) => {
    if (split && rep.constituency === uniqueConstituencyName) {
      const repContent = document.createElement("div");
      repContent.innerHTML = getRepContent(repContentTemplate, rep, false, subjectEl.innerHTML);
      regionalContent.appendChild(repContent);
    } else {
      const repContent = document.createElement("div");
      repContent.innerHTML = getRepContent(repContentTemplate, rep, false, subjectEl.innerHTML);
      content.appendChild(repContent);
      commonConstituencyName = rep.constituency;
    }
  });

  if (split) {
    regionalHeader.innerHTML = `Your ${type} for ${uniqueConstituencyName}`;
    regionalHeader.classList.add(...["text-lg", "mb-5"]);
    el.appendChild(regionalHeader);
    el.appendChild(regionalContent);
  }

  header.innerHTML = `Your ${type}s for ${commonConstituencyName}`;
  header.classList.add(...["text-lg", "mb-5"]);

  el.appendChild(header);
  el.appendChild(content);
}


function EmailMP(el) {

  // Set up vars for later use
  let country;
  let reps;

  // Get name, postcode, constituency from querystring
  const ukRepEl = el.querySelector("[data-uk]");
  const eireRepEl = el.querySelector("[data-eire]");
  const regRepEl = el.querySelector("[data-regional]");
  const emailEl = el.querySelector("[data-email-content]");
  const subjectEl = el.querySelector("[data-email-subject]");
  const copyButton = el.querySelector("[data-email-copy]");
  const messageEl = el.querySelector("[data-email-message]");
  const tabsEl = document.createElement("div");

  emailContent = emailEl.innerHTML;

  if (paramCountry === "ireland") {

    //  *** IRELAND *** If Ireland get TDs and add to DOM Element
    const eireRepHeader = document.createElement("h2");
    const eireRepContent = document.createElement("div");
    eireRepHeader.innerHTML = `Your Teachta Dálas for ${paramDailConstituency}`;
    eireRepHeader.classList.add(...["text-lg", "mb-5"]);

    // Get representative data from constituency name
    reps = JSON.parse(contacts).filter(
      ({ constituency }) => constituency === paramDailConstituency
    );

    reps.forEach((rep) => {
      const repContent = document.createElement("div");
      repContent.innerHTML = getRepContent(repContentTemplate, rep, true, subjectEl.innerHTML);
      eireRepContent.appendChild(repContent);
      allRepresentatives.push({"full_name": rep.full_name, "email": rep.email});
    });

    eireRepEl.appendChild(eireRepHeader);
    eireRepEl.appendChild(eireRepContent);

    // Update email content
    emailEl.innerHTML = emailContent.replace(/{name}/g, paramName).replace(/{representative_name}/g, reps[0].full_name);
    emailEl.setAttribute("data-name", paramName);
    emailEl.setAttribute("data-email-address", reps[0].email);
  } else {
    // Get country based on postcode
    country = getCountry(paramPostcode);

    // ***UK**** Get MP from API and add to DOM element
    const mp = JSON.parse(getMP(paramPostcode));

    if (mp.full_name !== undefined) {
      const ukRepHeader = document.createElement("h2");
      const ukRepContent = document.createElement("div");
      ukRepHeader.innerHTML = "Your member of parliament";
      ukRepHeader.classList.add(...["text-lg", "mb-5", "pt-5", "border-t-2", "border-light-blue"]);
      ukRepContent.innerHTML = getRepContent(constRepContentTemplate, mp, false, subjectEl.innerHTML);

      ukRepEl.appendChild(ukRepHeader);
      ukRepEl.appendChild(ukRepContent);

      // Add to array of representatives
      allRepresentatives.push({"full_name": mp.full_name, "email": getEmail(mp.full_name)});
      
    }

    // *** NI *** If Northern Ireland get MLAs and add to DOM element
    if (country === "northern-ireland") {
      listRegionalReps(false, JSON.parse(getMLA(paramPostcode)), "MLA", regRepEl);
    }

    // *** SCOTLAND *** If Scotland get MSPs and add to DOM elements
    if (country === "scotland") {
      listRegionalReps(true, JSON.parse(getMSP(paramPostcode)), "MSP", regRepEl);
    }

    if (paramCountry === "wales") {
      //  *** WALES *** If Ireland get MSs and add to DOM Element
      const walesRepHeader = document.createElement("h2");
      const walesRepContent = document.createElement("div");
      walesRepHeader.innerHTML = `Your Senedd Member for ${paramConstituency}`;
      walesRepHeader.classList.add(...["text-lg", "mb-5"]);

      // Get representative data from constituency name
      reps = JSON.parse(contacts).filter(
        ({ constituency }) => constituency === paramConstituency
      );

      if (reps.length > 0) {
        reps.forEach((rep) => {
          if (rep.country === "Wales") {
            const repContent = document.createElement("div");
            repContent.innerHTML = getRepContent(repContentTemplate, rep, true, subjectEl.innerHTML);
            walesRepContent.appendChild(repContent);
            allRepresentatives.push({"full_name": rep.full_name, "email": rep.email});
          }
        });

        regRepEl.appendChild(walesRepHeader);
        regRepEl.appendChild(walesRepContent);
      }
    }

    // Update email content
    emailEl.innerHTML = emailContent.replace(/{name}/g, paramName).replace(/{representative_name}/g, allRepresentatives[0].full_name);
    emailEl.setAttribute("data-name", paramName);
    emailEl.setAttribute("data-email-address", allRepresentatives[0].email);
  }

  // If more than one rep, create tabs

  allRepresentatives.forEach((rep, i) => {
    if (rep.email !== "") {
      const tab = document.createElement("button");
      tab.innerHTML = rep.full_name;
      tab.classList.add("btn-tab");

      if (i === 0) {
        tab.classList.add("selected");
      }

      tab.addEventListener("click", function(e) {
        const btns = document.querySelectorAll(".btn-tab");
        btns.forEach((btn) => {
          btn.classList.remove("selected");
        })
        e.target.classList.add("selected");
        emailEl.innerHTML = emailContent.replace(/{name}/g, paramName).replace(/{representative_name}/g, rep.full_name);
        emailEl.setAttribute("data-name", paramName);
        emailEl.setAttribute("data-email-address", rep.email);
      });

      tabsEl.appendChild(tab);
    }
  });
  
  tabsEl.classList.add("mb-8");

  if (allRepresentatives.length > 1) {
    emailEl.before(tabsEl);
  }

  // Copy message event listener
  copyButton.addEventListener("click", function(e) {
    e.preventDefault();
    const blobHtml = new Blob([emailEl.innerHTML], { type: "text/html" });
    const blobText = new Blob([emailEl.innerText], { type: "text/plain" });
    const data = [new window.ClipboardItem({
      "text/plain": blobText,
      "text/html": blobHtml,
    })];
    navigator.clipboard.write(data);

    messageEl.innerHTML = copiedMessageTemplate.replace(/{email}/g, emailEl.dataset.emailAddress).replace(/{subject}/g, subjectEl.innerHTML);
    messageEl.classList.remove("hidden");

    this.innerHTML = "Copied!";
  });
}

export function bindEmailMP() {
  return [...document.querySelectorAll("[data-mp-form]")].map((el) => EmailMP(el));
}
